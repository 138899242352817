import React from 'react';
import { Heading } from '../../components';
import css from './ListingPage.module.css';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SectionContactPerson = props => {
  const { publicData } = props;
  const { contactPerson, contactPhoneNumber } = publicData;

  return contactPerson || contactPerson ? (
    <div className={css.sectionText}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.contactPerson.title" />
      </Heading>
      {contactPerson && (
        <p className={css.contactInfo}>
          <FontAwesomeIcon className={css.iconUser} icon={'fa-solid fa-user'} /> {contactPerson}
        </p>
      )}
      {contactPhoneNumber && (
        <p className={css.contactInfo}>
          <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-phone'} /> {contactPhoneNumber}
        </p>
      )}
    </div>
  ) : null;
};

export default SectionContactPerson;
