import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import WorkShiftTimeUtil from '../../util/workShiftTime.util';
import { FormattedMessage } from 'react-intl';

const SectionWorkShift = props => {
  const { publicData } = props;
  const { shiftStartTime, shiftEndTime } = publicData;

  return shiftStartTime && shiftEndTime ? (
    <div className={css.sectionText}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.workShift.title" />
      </Heading>
      <span className={css.startEndTime}>
        <FormattedMessage
          id="ListingPage.workShift.startAndEndTime"
          values={{
            shiftStartTime: WorkShiftTimeUtil.convertToReadableTime(shiftStartTime),
            shiftEndTime: WorkShiftTimeUtil.convertToReadableTime(shiftEndTime),
          }}
        />
      </span>
    </div>
  ) : null;
};

export default SectionWorkShift;
